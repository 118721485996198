<template>
  <div v-if="currentRoute" class="event">
    <stage
        :stageLabel="$i18n.locale === 'en' ? 'Events' : 'Veranstaltungen'"
        class="event__stage-auto-height"
        :big="currentRoute.title.rendered.length < 25"
        :content="currentRoute.acf.facts"
        :signInButton="currentRoute.acf.anmeldung"
        :title="currentRoute.title.rendered"
        @signInClicked="setSignInOverlay(true)"
    />
    <div class="event__content">
        <div class="event__section-bg">
            <introduction
                :subline="'Beschreibung'"
                :maintext="currentRoute.acf.description"
                :next="nextLinks"
            />
        </div>
        <h3 v-if="currentRoute.acf.customer" class="event__sectionhead first">{{$i18n.locale === 'de' ? 'Förderer' : 'Sponsors'}}</h3>
        <ul
            v-if="currentRoute.acf.customer"
            class="event__partner-list"
        >
            <li
                v-for="(customer, i) in currentRoute.acf.customer"
                :key="i"
                class="event__partner-list-item"
            >
                <a v-if="customer.customer_link.url !== '#'" :href="customer.customer_link.url">
                    <h3>{{customer.customer_link.title}}</h3>
                </a>
                <h3 v-else>{{customer.customer_link.title}}</h3>
            </li>
        </ul>
        <h3 v-if="currentRoute.acf.partner" class="event__sectionhead first">{{$i18n.locale === 'de' ? 'Partner' : 'Partners'}}</h3>
        <ul
            v-if="currentRoute.acf.partner"
            class="event__partner-list"
        >
            <li
                v-for="(partner, i) in currentRoute.acf.partner"
                :key="i"
                class="event__partner-list-item"
            >
                <a v-if="partner.partner_link.url !== '#'" :href="partner.partner_link.url">
                    <h3>{{partner.partner_link.title}}</h3>
                </a>
                <h3 v-else>{{partner.partner_link.title}}</h3>
            </li>
        </ul>
    </div>
    <contact
        class="event__contact"
        v-if="currentRoute.acf.kontakt"
        fontWeight="normal"
        :contact="currentRoute.acf.kontakt"
        :image="currentRoute.acf.kontakt.image ? currentRoute.acf.kontakt.image : {}"
    />
    <!-- Normal Contact Form -->
    <div v-if="signInOverlayOpen" @click.self="setSignInOverlay(false)" ref="overlay" class="event__overlay">
        <div class="event__form-wrapper">
            <div @click="setSignInOverlay(false)" class="event__icon-wrapper">
                <icon-close/>
            </div>
            <div class="event__form-head">
                <label>Anmeldung</label>
                <h2 class="event__form-headline" v-html="currentRoute.title.rendered"/>
            </div>
            <div class="event__form">
                <form>
                    <div class="event__form-section boxes">
                        <label style="margin-right: 2rem;">Anrede*</label>
                        <label for="gender-female">
                            <input type="radio" name="gender" id="gender-female" value="Frau" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                            <div class="event__radiomark"></div>
                            <div class="event__radiomark-label">Frau</div>
                        </label>
                        <label for="gender-male">
                            <input type="radio" name="gender" id="gender-male" value="Herr" v-model="filterFields(formData ,'form-gender').value" class="event__radio" @change="validate(formData)"/>
                            <div class="event__radiomark"></div>
                            <div class="event__radiomark-label">Herr</div>
                        </label>
                        <label for="gender-none">
                            <input type="radio" name="gender" id="gender-none" value="Keine Angabe" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                            <div class="event__radiomark"></div>
                            <div class="event__radiomark-label">Keine Angabe</div>
                        </label>
                    </div>
                    <div class="event__form-section fields">
                        <label class="title" for="title">
                            <div class="event__text-label">Titel</div>
                            <input type="text" id="title" class="event__input-text" v-model="filterFields(formData, 'form-title').value" @change="validate(formData)"/>
                        </label>
                        <label class="name" for="name">
                            <div class="event__text-label">Vorname*</div>
                            <input type="text" id="name" class="event__input-text" v-model="filterFields(formData, 'form-name').value" @change="validate(formData)"/>
                        </label>
                        <label class="lastname" for="surname">
                            <div class="event__text-label">Nachname*</div>
                            <input type="text" id="surname" class="event__input-text" v-model="filterFields(formData, 'form-surname').value" @change="validate(formData)"/>
                        </label>
                        <label class="company" for="company">
                            <div class="event__text-label">Organisation / Firma*</div>
                            <input type="text" id="company" class="event__input-text" v-model="filterFields(formData, 'form-company').value" @change="validate(formData)"/>
                        </label>
                        <label class="email" for="email">
                            <div class="event__text-label">E-Mail-Adresse*</div>
                            <input type="email" id="email" class="event__input-text" v-model="filterFields(formData, 'form-email').value" @change="validate(formData)"/>
                        </label>
                    </div>
                    <template v-if="isSchoolEvent">
                        <h3>Workshop</h3>
                        <div class="event__form-section">
                            <label for="part">
                                <div class="event__text-label">Optionen der Teilnahme an:*</div>
                                <select id="part" v-model="filterFields(formData, 'form-workshop').value" @change="validate(formData)">
                                    <option>Bitte auswählen...</option>
                                    <option>Workshop 1 „Klimagefühle“</option>
                                    <option>Workhop 2 „Klimaschutz im Unterricht“</option>
                                </select>
                            </label>
                        </div>
                    </template>
                    <template v-if="isSustainabilityEvent">
                        <h3>Workshop</h3>
                        <div class="event__form-section">
                            <label for="lunch">
                                <div class="event__text-label">Ich esse:*</div>
                                <select id="lunch" v-model="filterFields(formData, 'form-lunch').value" @change="validate(formData)">
                                    <option>Fleisch</option>
                                    <option>Vegetarisch</option>
                                    <option>Vegan</option>
                                </select>
                            </label>
                            <label>
                                <div class="event__text-label">Unverträglichkeiten oder Sonstige Hinweise zum Essen:</div>
                                <input type="text" v-model="filterFields(formData, 'form-lunch-notes').value" @change="validate(formData)"/>
                            </label>
                        </div>
                        <div class="event__form-section">
                            <label for="double-room" class="checkbox">
                                <input type="checkbox" name="double-room" id="double-room" value="Doppelzimmer" v-model="filterFields(formData, 'form-doubleroom').value" class="event__checkbox" @change="validate(formData)"/>
                                <div class="event__checkmark"></div>
                                <div class="event__checkbox-label">Ich wünsche ein Doppelzimmer mit Teilnehmer*in:</div>
                                <input type="text" class="event__input-text" v-model="filterFields(formData, 'form-doubleroom-name').value" @change="validate(formData)"/>
                            </label>
                        </div>
                        <div class="event__form-section">
                            <label for="childcare" class="checkbox">
                                <input type="checkbox" name="childcare" id="childcare" value="Kinderbetreuung" v-model="filterFields(formData, 'form-childcare').value" class="event__checkbox" @change="validate(formData)"/>
                                <div class="event__checkmark"></div>
                                <div class="event__checkbox-label">Ich benötige Kinderbetreuung (Anmeldung bis zum 14.04.2024 nötig)</div>
                            </label>
                        </div>
                    </template>
                    <h3>Weiteres</h3>
                    <div class="event__form-section additional">
                        <label class="privacy" for="privacy">
                            <input type="checkbox" name="privacy" id="privacy" value="Datenschutz angenommen" v-model="filterFields(formData, 'form-privacy').value" class="event__checkbox" @change="validate(formData)"/>
                            <div class="event__checkmark"></div>
                            <div class="event__checkbox-label">Ich willige ein, dass meine Daten zur Organisation der Veranstaltung bei  NELA. Next Economy Lab verarbeitet werden. Ich kann meine Einwilligung jederzeit, kostenfrei und ohne die Angabe von Gründen widerrufen.</div>
                        </label>
                        <label class="media" for="media">
                            <input type="checkbox" name="media" id="media" value="Mediennutzung bestätigt" v-model="filterFields(formData, 'form-media').value" class="event__checkbox" @change="validate(formData)"/>
                            <div class="event__checkmark"></div>
                            <div class="event__checkbox-label">Bei den Veranstaltungen werden unter Umständen Foto-, Ton- und Filmaufnahmen angefertigt, die in verschiedenen on- und offline Medien veröffentlicht werden. Diese Aufnahmen sind mit der bildlichen Darstellung von anwesenden Personen verbunden, wobei die Personenauswahl zufällig erfolgt.</div>
                        </label>
                        <label class="news" for="news">
                            <input type="checkbox" name="news" id="news" value="Newsletter bestätigt" v-model="filterFields(formData, 'form-news').value" class="event__checkbox" @change="validate(formData)"/>
                            <div class="event__checkmark"></div>
                            <div class="event__checkbox-label">Ich möchte über weitere Events und Publikation von NELA. Next Economy Lab informiert werden</div>
                        </label>
                    </div>
                    <div class="event__form-section">
                        <label for="hearedfrom">
                            <h3 class="event__text-label">Wie haben Sie von diesem Event erfahren?</h3>
                            <select id="hearedfrom" v-model="filterFields(formData, 'form-heared').value" @change="validate(formData)">
                                <option>Bitte auswählen...</option>
                                <option>Werbung</option>
                                <option>Social Media</option>
                                <option>Einem Bekannten</option>
                            </select>
                        </label>
                    </div>
                    <div class="event__form-section">
                        <button class="event__button" :disabled="!valid" @click.prevent="sendContactForm(formData)">
                            {{ sendButtonText }}
                            <div v-if="isSending" class="loader">
                                <span class="loader__symbol"></span>
                            </div>
                        </button>
                        <transition name="drop-fade">
                        <div v-if="postMessage" class="event__post-message">{{postMessage}}</div>
                        </transition>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/project-intro.vue'
import contact from '@/components/introduction.vue'
import IconClose from '@/components/icons/icon-close.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

const sendButtonState = {
    default: 'Anmelden',
    sending: 'Wird gesendet...',
    send: 'Anmeldung gesendet',
    error: 'Fehler beim Senden'
}

export default {
  name: 'eventDetail',
  components: {
    Stage,
    Introduction,
    contact,
    IconClose,
  },
  data() {
      return {
          postMessage: '',
          signInOverlayOpen: false,
          isSending: false,
          isError: false,
          formData: [
              {
                  name: 'form-gender',
                  required: true,
                  value: '',
              },
              {
                  name: 'form-title',
                  required: false,
                  value: '',
              },
              {
                  name: 'form-name',
                  required: true,
                  value: '',
              },
              {
                  name: 'form-surname',
                  required: true,
                  value: '',
              },
              {
                  name: 'form-company',
                  required: true,
                  value: '',
              },
              {
                  name: 'form-email',
                  required: true,
                  value: '',
              },
              {
                  name: 'form-privacy',
                  required: true,
                  value: false,
              },
              {
                  name: 'form-media',
                  required: true,
                  value: false,
              },
              {
                  name: 'form-news',
                  required: false,
                  value: false,
              },
              {
                  name: 'form-heared',
                  required: false,
                  value: '',
              },
          ],
          valid: false,
      }
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    sendButtonText() {
        if(this.isSending) return sendButtonState.sending;
        if(this.postMessage === 'Vielen Dank für deine Nachricht. Sie wurde gesendet.' && !this.isSending) return sendButtonState.send;
        if(this.isError) return sendButtonState.error;
        return sendButtonState.default;
    },
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    formID() {
        if(this.isSchoolEvent) return '2407'
        if(this.isSustainabilityEvent) return '2468'
        return '685';
    },
    isSchoolEvent() {
        const schoolEventIds = [2387];
        return schoolEventIds.includes(this.currentRoute.id)
    },
    isSustainabilityEvent() {
        const EventIds = [2454];
        return EventIds.includes(this.currentRoute.id)
    },
    hasPartOne() {
        const { value } = this.formData.find(elm => elm.name === 'form-part');
        return value === 'Teil I: Kurzimpule und interaktive Workshops (11:00 - 16:00)' || value === 'Teil I + Teil II (11.00 - 20.00 Uhr)';
    },
    nextLinks() {
        if(!this.currentRoute.acf.addLinks.length) return [];
        const links = this.currentRoute.acf.addLinks.map(({addLinks_link}) => addLinks_link);
        return links ? links : [];
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  watch: {
      currentRoute() {
        this.$nextTick(() => {
            if(this.$route.hash === '#register') this.setSignInOverlay(true);
            if(this.isSchoolEvent) this.formData = [
                ...this.formData,
                {
                    name: 'form-workshop',
                    required: true,
                    value: '',
                },
            ];
            if(this.isSustainabilityEvent) this.formData = [
                ...this.formData,
                {
                    name: 'form-lunch',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-lunch-notes',
                    required: false,
                    value: '',
                },
                {
                    name: 'form-doubleroom',
                    required: false,
                    value: '',
                },
                {
                    name: 'form-doubleroom-name',
                    required: false,
                    value: '',
                },
                {
                    name: 'form-childcare',
                    required: false,
                    value: '',
                },
            ]
        });
      },
      $route(to) {
        this.$nextTick(() => {
            if(to.hash === '#register') this.setSignInOverlay(true);
        });
      },
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
    sendContactForm(dataForm) {
        if(this.valid) {
        this.isSending = true;
        const form = new FormData();
        form.append('form-event', this.currentRoute.title.rendered);
        dataForm.forEach(elm => {
            if (typeof elm.value === 'string') form.append(elm.name, elm.value);
            if (typeof elm.value === 'boolean') form.append(elm.name, elm.value ? 'Akzeptiert' : 'Abgelehnt');
        })
        axios.post(`https://nexteconomylab.de/wp/wp-json/contact-form-7/v1/contact-forms/${this.formID}/feedback`, form)
        .then(res => {
            this.postMessage = res.data.message;
            this.isSending = false;
        }).catch(() => {
            this.isSending = false;
            this.isError = true;
            this.postMessage = 'Fehler beim Senden der Anmeldung. Bitte versuchen Sie es später erneut.';
        });
        }
    },
    filterFields(data, field) {
        return data.filter(data => data.name === field)[0];
    },
    setSignInOverlay(state) {
        if(state) {
            this.signInOverlayOpen = state;
            window.scrollTo({
                top: 0,
                left: 0,
            });
        } else {
            this.signInOverlayOpen = state;
            if(this.$route.hash) this.$router.push({hash: ''});
        }
    },
    validate(dataList) {
        const required = dataList.filter(elm => {
            return elm.required;
        })
        const filled = required.filter(elm => elm.value).length >= required.length;
        
        this.valid = filled;
    }
  }
}
</script>
<style lang="scss">
.event {
    &__stage-auto-height {
        height: auto !important;
        @media (min-width: 1024px) {
            min-height: 442px;
        }
    }
}
</style>
<style lang="scss" scoped>
.event {
    margin-top: 86px;
    padding: 0 .8rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
    margin-bottom: 150px;
    }
    &__content {
        max-width: 1140px;
        margin: auto;
        overflow: hidden;
        @media (min-width: 768px) {
           padding: 0 1.6rem;
        }
        @media (min-width: 1024px) {
            padding: 0;
        }
    }
    &__sectionhead {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family:"neue-plak";
        font-weight: 500;
        color: #182952;
        margin: 0 0 0 1.6rem;
        &--left {
            justify-content: flex-start;
        }
        @media (min-width: 768px) {
            margin: 0;
        }
        @media (min-width: 1024px) {
            margin: 0 0 0 110px;
        }
    }
    &__partner-list {
        padding: 0;
        margin: 0;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        @media (min-width: 1140px) {
            padding: 0 110px;
        }
    }
    &__partner-list-item {
        margin: 0;
        list-style-type: none;
        padding: .8rem;
        h3 {
            margin: 0;
        }
    }
    &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 65px;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.4);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;
    }
    &__form-wrapper {
        max-width: 1200px;
        width: 100%;
        height: auto;
        padding: 1.6rem;
        background-color: #ffffff;
        position: relative;
        margin-top: 80px;
        @media (min-width: 768px) {
            padding: 65px;
            margin-top: 100px;
            border-radius: 25px;
        }
    }
    &__icon-wrapper {
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        width: 30px;
        height: 30px;
        color: inherit;
        cursor: pointer;
    }
    &__form-section {
        display: flex;
        margin-bottom: 1.6rem;
        gap: 1.5rem;

        input[type=text],
        input[type=email],
        input[type=number],
        textarea,
        select {
            border: none;
            outline: none;
            background-color: #f2f2f2;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            padding: .8rem 1rem;
            width: 100%;
            margin-top: .8rem;
        }
        input[type=radio],
        input[type=checkbox] {
            margin-right: .8rem;
            min-width: 1rem;
            min-height: 1rem;
            max-width: 1rem;
            max-height: 1rem;
            width: 1rem;
            height: 1rem;
        }
        &.boxes {
            flex-direction: column;
            
            @media (min-width: 768px) {
                flex-direction: row;
            }

            label {
                display: flex;
                margin-bottom: .8rem;
                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
        &.additional {
            display: flex;
            flex-direction: column;
            label {
                display: flex;
                margin-bottom: 1.6rem;

                &:first-child {
                    margin-right: 1rem;
                }
            }
            @media (min-width: 768px) {
                display: grid;
                grid-template-areas: 
                "privacy media"
                "news .";
                &.privacy {
                    grid-area: privacy;
                }
                &.media {
                    grid-area: media;
                }
                &.news {
                    grid-area: news;
                }
                label {
                    margin-bottom: 0;
                }
            }
        }
        &.fields {
            display: flex;
            flex-direction: column;
            label {
                margin-bottom: 1.6rem;
            }
            @media (min-width:768px) {
                display: grid;
                grid-template-areas: 
                "title title name name name name name lastname lastname lastname lastname lastname"
                "company company company company company company email email email email email email";
                grid-gap: 1.6rem;
                .title {
                    grid-area: title;
                }
                .name {
                    grid-area: name;
                }
                .lastname {
                    grid-area: lastname;
                }
                .company {
                    grid-area: company;
                }
                .email {
                    grid-area: email;
                }  
                label {
                    margin-bottom: 0;
                } 
            }
        }
    }
    &__form-headline {
        font-family: "neue-plak";
        font-weight: 500;
        color: #182952;
        margin-top: 0;
        margin-bottom: 1.6rem;
    }
    &__button {
        margin: 0 auto 0 0;
        text-decoration: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 16px;
        border: none;
        outline: none;
        background-color: #0c3818;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: center;
        &:hover {
            background-color: #5e6534;
        }
        &:disabled {
            background-color: #f2f2f2;
            color: #555555;

        }

        .loader {
            position: relative;
            height: auto;
            background: transparent;

            .loader__symbol {
                height: 1rem;
                width: 1rem;
                border: 2px solid currentColor;
                border-bottom-color: transparent;
            }
        }
    }
    &__contact {
        margin-top: 65px;
        background-color: #e8edf8;
    }
    &__partner-list-item {
        a {
            text-decoration: none;
            color: inherit;
            transition: color 300ms;
            &:hover {
                text-decoration: underline;
                color: #f3972b;
            }
        }
    }
    &__post-message {
        color:#182952;
        font-weight: bold;
    }
    .finances, .knowledge {
        flex-direction: column;
        .form-wrapper {
            display: flex;
            margin: 1rem 0 2rem;
        }

        label {
            display: flex;
            margin-left: 2rem;
            input {
                margin-right: .8rem;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .expectations, .information {
        flex-direction: column;

        textarea {
            border: none;
            outline: none;
            background-color: #f2f2f2;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            padding: .8rem 1rem;
            width: 100%;
        }
    }

    .checkbox {
        display: flex;
        align-items: center;

        .event__checkbox-label {
            flex: 0 0 auto;
            margin-right: 1.6rem;
        }
        .event__input-text {
            margin: 0;
        }
    }
}
</style>
