<template>
  <div v-if="currentRoute" class="home">
    <stage :stageLabel="$i18n.locale === 'de' ? 'Projekte' : 'Projects'" class="home__stage-auto-height" :big="currentRoute.title.rendered.length < 25" :title="currentRoute.title.rendered" :downloads="downloads" @scrollToDownloads="handleScrollTo()"/>
    <div class="home__content">
        <div class="home__section-bg">
            <introduction
                :subline="$i18n.locale === 'de' ? 'Beschreibung' : 'Description'"
                :maintext="currentRoute.acf.description"
                :contact="currentRoute.acf.contact.contact_href"
                :timeframe="currentRoute.acf.duration"
                :next="downloads.links || []"
            />
            <h3 v-if="currentRoute.acf.team" class="home__sectionhead home__sectionhead--left">{{$i18n.locale === 'de' ? 'Projektteam' : 'Projectteam'}}</h3>
            <team v-if="currentRoute.acf.team" :members="currentRoute.acf.team"/>
        </div>
        <template v-if="downloads.links.length">
            <h3 class="home__head" ref="downloads">
                {{$i18n.locale === 'de' ? 'Weiterführendes' : 'Further Information'}}
            </h3>
            <div class="downloads">
                <ul class="downloads__list">
                    <li class="downloads__list-item" v-for="(item, i) in downloads.links" :key="`item-${i}`">
                        <h3 class="downloads__title">{{ item.title }}</h3>
                        <link-button class="downloads__button" v-if="getItemLabel" :link="item.url">{{getItemLabel[i].label}}</link-button>
                    </li>
                    <li class="downloads__list-item downloads__list-item--package" v-if="downloads.allProjectDownloads">
                        <h3 class="downloads__title">{{ downloads.allProjectDownloads.title }}</h3>
                        <link-button class="downloads__button" :link="downloads.allProjectDownloads.url">{{$i18n.locale === 'de' ? 'Alle herunterladen' : 'Download all files'}}</link-button>
                    </li>
                </ul>
            </div>
        </template>
        <h3 v-if="currentRoute.acf.customer" class="home__head">{{$i18n.locale === 'de' ? 'Förderer' : 'Sponsors'}}</h3>
        <ul
            v-if="currentRoute.acf.customer"
            class="home__partner-list"
        >
            <li
                v-for="(customer, i) in currentRoute.acf.customer"
                :key="i"
                class="home__partner-list-item"
            >
                <a v-if="customer.image" :href="customer.customer_link.url ? customer.customer_link.url : '#'">
                    <img class="home__partner-image" :src="customer.image.sizes.medium_large"/>
                </a>
                <link-button v-if="customer.customer_link.url !== '#' && !customer.image" :type="'link'" :link="customer.customer_link.url">
                    <h3>{{customer.customer_link.title}}</h3>
                </link-button>
                <h3 v-if="!customer.customer_link.url && !customer.image">{{customer.customer_link.title}}</h3>
            </li>
        </ul>
        <tag-posts class="home__tagposts" header="Gehört zu" :posts="tagPosts" :route="$route.params.name"/>
        <h3 v-if="currentRoute.acf.partner" class="home__head">Partner</h3>
        <ul
            v-if="currentRoute.acf.partner"
            class="home__partner-list"
        >
            <li
                v-for="(partner, i) in currentRoute.acf.partner"
                :key="i"
                class="home__partner-list-item"
            >
                <img class="home__partner-image" v-if="partner.image" :src="partner.image.sizes.medium_large"/>
                <link-button v-if="partner.partner_link.url !== '#' && !partner.image" :type="'link'" :link="partner.partner_link.url">
                    <h3>{{partner.partner_link.title}}</h3>
                </link-button>
                <h3 v-else>{{partner.partner_link.title}}</h3>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/project-intro.vue'
import Team from '@/components/project-team.vue'
import TagPosts from '@/components/tag-posts.vue'
import linkButton from '@/components/link-button.vue'
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    Stage,
    Introduction,
    Team,
    TagPosts,
    linkButton,
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    downloads(){
        if (this.currentRoute && this.currentRoute.acf.addLinks) {
            const {allProjectDownloads} = this.currentRoute.acf
            const downloads = this.currentRoute.acf.addLinks.map(({addLinks_link}) => addLinks_link);
            const validateDownloads = downloads.filter(({url}) => url);
            return {
                links: validateDownloads,
                allProjectDownloads: allProjectDownloads || undefined
            }
        }
        return {
            links: [],
            allProjectDownloads: undefined
        };
    },
    getItemLabel(){
        if(!this.currentRoute || !this.downloads.links.length) return [];
        const downloadables = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', '7z', 'jpg', 'jpeg', 'png', 'gif', 'svg', 'mp4', 'mp3', 'wav', 'avi', 'mov', 'flv', 'wmv', 'mkv', 'webm', 'ogg', 'ogv', 'webp', 'bmp', 'tiff', 'tif', 'eps', 'ai', 'psd', 'indd', 'svg', 'svgz', 'dwg', 'dxf', 'dwf', 'dwt', 'dws']
        const inlcudesDownloadables = (url) => downloadables.some(ext => url.includes(ext));
        const mappedLinks = this.downloads.links.map(({url}) => ({
            label: inlcudesDownloadables(url) ? this.$i18n.locale === 'de' ? 'Herunterladen' : 'Download' : this.$i18n.locale === 'de' ? 'Öffnen' : 'Open'
        }));
        return mappedLinks;
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
    handleScrollTo() {
        const downloads = this.$refs.downloads;
        downloads.scrollIntoView({block: 'center', inline: 'nearest', behavior: 'smooth'});
    },
  }
}
</script>
<style lang="scss">
.home {
    &__stage-auto-height {
        height: auto !important;
        @media (min-width: 1024px) {
            min-height: 442px;
        }
    }
}
</style>
<style lang="scss" scoped>
.home {
    margin-top: 86px;
    padding: 0 .8rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
    margin-bottom: 150px;
    }
    &__content {
        max-width: 1140px;
        margin: auto;
        overflow: hidden;
        @media (min-width: 768px) {
        padding: 0 1.6rem;
    }
    @media (min-width: 1024px) {
        padding: 0;
    }
}
&__sectionhead {
    display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family:"neue-plak";
        font-weight: 500;
        color: #182952;
        margin: 0 0 0 1.6rem;
        &--left {
            justify-content: flex-start;
        }
        @media (min-width: 768px) {
            margin: 0;
        }
        @media (min-width: 1024px) {
            margin: 0 0 0 110px;
        }
}
&__head {
    margin: auto;
    text-align: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 1.5rem;
}
&__tagposts {
    margin-bottom: 65px;
}
&__partner-list {
    padding: 0;
    margin: 0 0 1.5rem;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    @media (min-width: 1140px) {
        padding: 0 110px;
    }
  }
&__partner-list-item::v-deep {
    margin: 0;
    list-style-type: none;
    padding: .8rem;
    .link-button__icon {
        display: none;
    }
    a {
        color: #182952;
        transition: color 150ms;
        &:hover {
            color: #f3972b;
        }
    }
    h3 {
        margin: 0;
    }
}
&__partner-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
&__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 65px;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
}

.downloads {
    &__list, &__list-item {
        padding: 0;
        margin: 0;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1.6rem;
        margin-bottom: 1.6rem;
        @media (min-width: 1024px) {
            grid-template-columns: repeat(auto-fill, minmax(300px, calc(50% - 15px)));
            grid-column-gap: 30px;
            grid-row-gap: 60px;
            margin-bottom: 150px;
        }
    }
    &__list-item {
        list-style-type: none;
        padding: 30px;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
        aspect-ratio: 4 / 3;

        &--package {
            border: 2px solid #0d3817;
            background: #f2f0e9;
        }
        @media (min-width: 1024px) {
            min-height: 335px;
        }
        h3 {
            color: #182952;
            font-size: 20px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            margin: .8rem 0 0 0;
            flex: 1;
            @media (min-width: 1024px) {
                margin: 24px 0 0 0;
                font-size: 25px;
                letter-spacing: .1px;
            }
        }
    }
    &__button {
        margin: 0 auto 0 0;
        flex: 0 0 auto;
    }
}
</style>
