import { render, staticRenderFns } from "./projekt-detail.vue?vue&type=template&id=c3efb1b8&scoped=true"
import script from "./projekt-detail.vue?vue&type=script&lang=js"
export * from "./projekt-detail.vue?vue&type=script&lang=js"
import style0 from "./projekt-detail.vue?vue&type=style&index=0&id=c3efb1b8&prod&lang=scss"
import style1 from "./projekt-detail.vue?vue&type=style&index=1&id=c3efb1b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3efb1b8",
  null
  
)

export default component.exports